var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('with-side-navigations',{attrs:{"side":"left"},scopedSlots:_vm._u([{key:"sideNavigation",fn:function(){return [_c('admins-sidebar')]},proxy:true}])},[_c('div',[_c('div',{staticClass:"flex flex-wrap gap-2 items-center justify-between"},[_c('div',{staticClass:"flex items-center"},[_c('h1',{staticClass:"text-3xl"},[_vm._v(_vm._s(_vm.$t('employee-list-view.employees')))]),_c('pagination-select',{attrs:{"query":_vm.routeQuery},on:{"select-limit":_vm.selectLimit}}),_c('filter-toggler',{staticClass:"ml-4"})],1),_c('router-link',{attrs:{"to":{ name: 'AddAdmin' }}},[_c('btn',{staticClass:"w-26"},[_vm._v(_vm._s(_vm.$t('common.add')))])],1)],1),_c('table-list',{staticClass:"pb-16",attrs:{"content":"users","query":_vm.computedQuery,"route-component":"Admins","force-update":_vm.listUpdatesCounter,"cols":{
        firstName: _vm.$t('employee-list-view.first-name'),
        lastName: _vm.$t('employee-list-view.last-name'),
        email: { label: _vm.$t('employee-list-view.e-mail'), formatter: _vm.email },
        phone: _vm.$t('employee-list-view.phone'),
      },"actions":Object.assign({}, (_vm.showUserPreview && {
          preview: function (id) { return _vm.previewUser(id, 'Admins'); },
        }),
        {edit: _vm.onEditBtnClick,
        delete: {
          handler: _vm.onRemoveBtnClick,
          modal: function (user) { return ({
            type: 'confirm',
            title: _vm.$t('employee-list-view.modal-title'),
            description: _vm.$t('employee-list-view.modal-description', {
              name: ((user.firstName) + " " + (user.lastName)),
            }),
            btnText: _vm.$t('employee-list-view.modal-btn'),
          }); },
        }}),"checkbox":"","selected-ids":_vm.selectedUsers},on:{"select":function($event){_vm.selectedUsers = $event}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }