<template>
  <div>
    <filter-group v-slot="{ resetFilters, filtersApplied }" v-model="filters">
      <div class="flex flex-wrap items-center justify-between">
        <h1 class="text-lg font-semibold mb-4 md:mb-0">
          {{ $t('common.filter') }}
        </h1>
        <btn
          v-if="filtersApplied"
          theme="secondary"
          class="w-26"
          @click="resetFilters"
        >
          {{ $t('common.reset') }}
        </btn>
      </div>
      <filter-wrapper
        :parameter="FILTER_PARAMS.SEARCH"
        :value="filters[FILTER_PARAMS.SEARCH]"
      >
        <text-input
          v-model="filters[FILTER_PARAMS.SEARCH]"
          :icon="mdiMagnify"
          :placeholder="$t('common.search')"
          debounce
          clearable
          class="mb-5 mt-6"
        />
      </filter-wrapper>
    </filter-group>
  </div>
</template>

<script>
import Btn from '@/components/Button/Button';
import FilterGroup from '@/components/Filters/FilterGroup';
import FilterWrapper from '@/components/Filters/FilterWrapper';
import { TextInput } from '@/components/Inputs';
import { FILTER_PARAMS } from '@/constants/filters';
import { mdiMagnify } from '@mdi/js';

export default {
  name: 'UsersSidebar',
  components: {
    FilterGroup,
    FilterWrapper,
    TextInput,
    Btn,
  },
  data() {
    return {
      mdiMagnify,
      FILTER_PARAMS,
      filters: {
        [FILTER_PARAMS.SEARCH]: '',
        [FILTER_PARAMS.SORT_BY]: null,
      },
    };
  },
};
</script>

<style lang="scss">
.sort-label {
  @apply uppercase text-sm text-gray-dark font-semibold;
}
</style>
